import { Routes, Route, Link } from 'react-router-dom'; 
import './index.css'

import { HomePage } from './pages/home';
import { CatalogPage } from './pages/catalog';
import { SuicideForestPage } from './pages/suicide-forest';
import { WhatIsAHeart } from './pages/what-is-a-heart';
import { CartPage } from './pages/cart';
import { FormPage } from './pages/form';

function App() {
  return (
    <div className='body'>
      <div style={{ position: 'fixed', width: '100%', height: '42.5px', backgroundColor: '#000', top: 0, left: 0, zIndex: 999}}>
        <div style={{position: 'absolute', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center',}}>
          <img className='_pc' style={{ width: '35px', paddingLeft: '10px', marginTop: '6px' }} src='/img/logo/logo.png' alt='Logo'/>
        </div>
        <div style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
          <Link style={{ padding: '10px', fontSize: '18px', color: '#fff', textDecoration: 'none' }} to='/'>HOME</Link>
          <Link style={{ padding: '10px', fontSize: '18px', color: '#fff', textDecoration: 'none' }} to='/catalog'>CATALOG</Link>
          <Link style={{ padding: '10px', fontSize: '18px', color: '#fff', textDecoration: 'none' }} to='/cart'>CART</Link>
        </div>
      </div>

      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/catalog' element={<CatalogPage/>} />
        <Route path='/about' element={<CatalogPage/>} />
        <Route path='/catalog/suicide-forest' element={<SuicideForestPage/>} />
        <Route path='/catalog/what-is-a-heart' element={<WhatIsAHeart/>} />
        <Route path='/cart' element={<CartPage/>} />
        <Route path='/form' element={<FormPage/>} />
      </Routes>

      <div className='_pc' style={{ width: '100%', height: '200px', flexDirection: 'column', bottom: 0, marginTop: '150px' }}> 
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: '60px',height: '50px', paddingRight: '10px' }} src='/img/logo/logo.png' alt='img' />
                <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>ABSTRACTION</h1>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <a href='https://vk.com/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>VKONTAKTE</a>
                <a href='https://t.me/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>TELEGRAM</a>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Link to='/catalog' style={{ fontSize: '28px', fontWeight: '100', color: '#fff', padding: '0px 10px 0px 10px', marginTop: '5px', cursor: 'pointer', textDecoration: 'none' }}>CATALOG</Link>
            </div>
        </div>
    </div>
  );
}

export default App;
