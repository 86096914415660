import React from 'react';
import '../index.css';
import { Link } from 'react-router-dom'; 

function HomePage() {
  return (
    <div className='body'>
        <div style={{ width: '100%', height: '100vh', }}>
            <img className='img-drag' style={{ width: '100%', height: '100%', objectFit: 'cover', animation: 'fadeInAnimation ease 5s', zIndex: 1 }} src='/img/catalog/what-is-a-heart/wiah1.jpg' alt='home' />
        </div>

        <div className='_pc' style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{ width: '50%', marginTop: '2%', marginLeft: '7%' }}> 
                <img style={{ width: '100%' }} src='/img/catalog/what-is-a-heart/wiah3.jpg' alt ="suicude-forest" />
            </div>
            <div style={{ width: '30%', textAlign: 'left', marginRight: '5%', marginTop: '4%', }}>
                <img style={{ width: '100%' }} src='/img/catalog/what-is-a-heart/wiah01.jpg' alt ="suicude-forest" />
                <div style={{ width: '100%', marginTop: '-20px' }}>
                    <h1 style={{ color: '#fff', fontSize: '32px', fontWeight: '100' }}>T-SHIRT "WHAT IS A HEART"</h1>
                    <h1 style={{ color: '#ffffff6a', fontSize: '24px', fontWeight: '100', marginTop: '-20px', textDecoration: 'line-through' }}>2 900</h1>
                    <h1 style={{ color: '#fff', fontSize: '28px', fontWeight: '100', marginTop: '-20px' }}>2 600 RUB</h1>
                    <h1 style={{ color: '#ffffff6a', fontSize: '28px', fontWeight: '100', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                    <Link to='/catalog/what-is-a-heart' style={{ display: 'block', color: '#fff', fontSize: '48px', fontWeight: '100', marginTop: '-15px', cursor: 'pointer', textDecoration: 'none',  }}>BUY NOW</Link>
                </div>
            </div>
        </div>

        <div className='_mobile' style={{ width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ width: '75%', textAlign: 'center', marginTop: '15%', }}>
                <img style={{ width: '100%' }} src='/img/catalog/what-is-a-heart/wiah01.jpg' alt ="suicude-forest"></img>
                <div style={{ width: '100%', marginTop: '-20px' }}>
                    <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100' }}>T-SHIRT "WHAT IS A HEART"</h1>
                    <h1 style={{ color: '#ffffff6a', fontSize: '24px', fontWeight: '100', marginTop: '-25px', textDecoration: 'line-through' }}>2 900</h1>
                    <h1 style={{ color: '#fff', fontSize: '28px', fontWeight: '100', marginTop: '-25px' }}>2 600 RUB</h1>
                    <h1 style={{ color: '#ffffff6a', fontSize: '26px', fontWeight: '100', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                    <Link to='/catalog/what-is-a-heart' style={{ display: 'block', color: '#fff', fontSize: '32px', fontWeight: '100', marginTop: '-5px', cursor: 'pointer', textDecoration: 'none',  }}>BUY NOW</Link>
                </div>
            </div>

            <div style={{ width: '100%', height: '200px', display: 'flex', flexDirection: 'column', bottom: 0, marginTop: '30px', paddingBottom: '30px' }}> 
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: '60px',height: '50px', paddingRight: '10px' }} src='/img/logo/logo.png' alt='img' />
                    <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>abstraction</h1>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <a href='https://vk.com/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>VKONTAKTE</a>
                    <a href='https://t.me/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>TELEGRAM</a>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to='/catalog' style={{ fontSize: '28px', fontWeight: '100', color: '#fff', padding: '0px 10px 0px 10px', marginTop: '5px', cursor: 'pointer', textDecoration: 'none' }}>CATALOG</Link>
                </div>
            </div>
        </div>
    </div>
  );
}

export { HomePage };
