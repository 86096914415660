import React, { useState, useEffect } from 'react';
import '../index.css';
import { Link, useLocation } from 'react-router-dom';

function CartPage() {
    const { pathname } = useLocation();
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const checkCookie = () => {
            const normalCookieValue = getCookie('suicide-forest-normal');
            const bigCookieValue = getCookie('suicide-forest-big');
            const WhatIsAHeartNormalCookieValue = getCookie('what-is-a-heart-normal');
            const WhatIsAHeartBigCookieValue = getCookie('what-is-a-heart-big');
        
            const items = [];
            let totalPrice = 0;
        
            if (normalCookieValue) {
                const normalItem = {
                    id: 1,
                    name: 'T-SHIRT "SUICIDE FOREST"',
                    price: 2600,
                    size: 'NORMAL',
                    image: '/img/catalog/suicude-forest/suicude-forest-1.jpg',
                    quantity: parseInt(normalCookieValue)
                };
                items.push(normalItem);
                totalPrice += normalItem.price * normalItem.quantity;
            }
        
            if (bigCookieValue) {
                const bigItem = {
                    id: 2,
                    name: 'T-SHIRT "SUICIDE FOREST"',
                    price: 2500,
                    size: 'BIG',
                    image: '/img/catalog/suicude-forest/suicude-forest-1.jpg',
                    quantity: parseInt(bigCookieValue)
                };
                items.push(bigItem);
                totalPrice += bigItem.price * bigItem.quantity;
            }

            if (WhatIsAHeartNormalCookieValue) {
                const wiahNormalItem = {
                    id: 3,
                    name: 'T-SHIRT "WHAT IS A HEART"',
                    price: 2600,
                    size: 'NORMAL',
                    image: '/img/catalog/what-is-a-heart/wiah01.jpg',
                    quantity: parseInt(WhatIsAHeartNormalCookieValue)
                };
                items.push(wiahNormalItem);
                totalPrice += wiahNormalItem.price * wiahNormalItem.quantity;
            }

            if (WhatIsAHeartBigCookieValue) {
                const wiahBigItem = {
                    id: 4,
                    name: 'T-SHIRT "WHAT IS A HEART"',
                    price: 2500,
                    size: 'BIG',
                    image: '/img/catalog/what-is-a-heart/wiah02.jpg',
                    quantity: parseInt(WhatIsAHeartBigCookieValue)
                };
                items.push(wiahBigItem);
                totalPrice += wiahBigItem.price * wiahBigItem.quantity;
            }
        
            setCartItems(items);
            setTotalPrice(totalPrice);
            setTotalPriceCookie(totalPrice); 
        };

        checkCookie();
    }, [pathname]);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const setCookie = (name, value) => {
        document.cookie = `${name}=${value}; path=/`;
    };

    const setTotalPriceCookie = (value) => {
        document.cookie = `total-price=${value}; path=/`;
    };

    const handleIncreaseQuantity = (id) => {
        setCartItems(prevItems => prevItems.map(item => {
            if (item.id === id) {
                const updatedQuantity = item.quantity + 1;
                const updatedTotalPrice = totalPrice + item.price;
                setCookie(item.id === 1 ? 'suicide-forest-normal' : item.id === 2 ? 'suicide-forest-big' : item.id === 3 ? 'what-is-a-heart-normal' : 'what-is-a-heart-big', updatedQuantity);
                setTotalPrice(updatedTotalPrice);
                setTotalPriceCookie(updatedTotalPrice);
                return { ...item, quantity: updatedQuantity };
            }
            return item;
        }));
    };
    
    const handleDecreaseQuantity = (id) => {
        setCartItems(prevItems => prevItems.map(item => {
            if (item.id === id && item.quantity > 1) {
                const updatedQuantity = item.quantity - 1;
                const updatedTotalPrice = totalPrice - item.price;
                setCookie(item.id === 1 ? 'suicide-forest-normal' : item.id === 2 ? 'suicide-forest-big' : item.id === 3 ? 'what-is-a-heart-normal' : 'what-is-a-heart-big', updatedQuantity);
                setTotalPrice(updatedTotalPrice);
                setTotalPriceCookie(updatedTotalPrice);
                return { ...item, quantity: updatedQuantity };
            }
            return item;
        }));
    };
    
    const handleRemoveFromCart = (id) => {
        setCartItems(prevItems => {
            const removedItem = prevItems.find(item => item.id === id);
            const updatedTotalPrice = totalPrice - (removedItem.price * removedItem.quantity);
            setTotalPrice(updatedTotalPrice);
            setTotalPriceCookie(updatedTotalPrice);
            return prevItems.filter(item => item.id !== id);
        });
        document.cookie = `${id === 1 ? 'suicide-forest-normal' : id === 2 ? 'suicide-forest-big' : id === 3 ? 'what-is-a-heart-normal' : 'what-is-a-heart-big'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    return (
        <div className='body'>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
                <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>Cart</h1>
            </div>

            <div style={{ width: '100%', minHeight: '900px' }}>
                {cartItems.map(item => (
                    <div className='_pc' key={item.id} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '25px' }}>
                        <img style={{ width: '10%' }} src={item.image} alt='img-cart' />                        
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px' }}>
                            <h1 style={{ width: '550px', color: '#fff', fontSize: '42px', fontWeight: '100' }}>{item.name}</h1>
                            <p style={{ color: '#fff', fontSize: '32px', marginTop: '-20px', fontWeight: '100' }}>PRICE: {item.price} RUB</p>
                        </div>
                        <p style={{ width: '300px', color: '#fff', fontSize: '32px', marginLeft: '50px', fontWeight: '100' }}>SIZE: {item.size}</p>
                        <p style={{ display: 'flex', flexDirection: 'row', color: '#fff', alignItems: 'center', marginLeft: '-40px' }}>
                            <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '24px' }} onClick={() => handleIncreaseQuantity(item.id)}>+</button>
                            <h1 style={{ color: '#fff', fontSize: '36px', fontWeight: '100', padding: '0px 20px 0px 20px' }}>{item.quantity}</h1>
                            <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '24px' }} onClick={() => handleDecreaseQuantity(item.id)}>-</button>
                        </p>
                        <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '22px', fontWeight: '100', marginLeft: '25px' }} onClick={() => handleRemoveFromCart(item.id)}>✖︎</button>
                    </div>
                ))}

                {cartItems.map(item => (
                    <div className='_mobile' key={item.id} style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '50px' }}>
                        <img style={{ width: '75%' }} src={item.image} alt={item.name} />                        
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                            <h1 style={{ color: '#fff', fontSize: '32px', fontWeight: '100' }}>{item.name}</h1>
                            <p style={{ color: '#fff', fontSize: '26px', marginTop: '-20px', fontWeight: '100' }}>PRICE: {item.price} RUB</p>
                        </div>
                        <p style={{ color: '#fff', fontSize: '32px', fontWeight: '100', marginTop: '-5px' }}>SIZE: {item.size}</p>
                        <p style={{ display: 'flex', flexDirection: 'row', color: '#fff', alignItems: 'center', marginTop: '-15px' }}>
                            <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '24px' }} onClick={() => handleIncreaseQuantity(item.id)}>+</button>
                            <h1 style={{ color: '#fff', fontSize: '36px', fontWeight: '100', padding: '0px 20px 0px 20px' }}>{item.quantity}</h1>
                            <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '24px' }} onClick={() => handleDecreaseQuantity(item.id)}>-</button>
                        </p>
                        <button style={{ width: '50px', height: '50px', backgroundColor: '#000', border: 'solid 1px #282828', color: '#fff', fontSize: '22px', fontWeight: '100', marginTop: '0px' }} onClick={() => handleRemoveFromCart(item.id)}>✖︎</button>
                    </div>
                ))}

                <div style={{ display: cartItems.length === 0 ? 'none' : 'block' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px', }}>
                        <p style={{ fontSize: '20px', color: '#fff' }}>TOTAL PRICE: {totalPrice} RUB</p>
                    </div>
                </div>

                <div style={{ display: cartItems.length === 0 ? 'none' : 'block' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-10px   ' }}>
                        <Link className='_pc'  to='/form' style={{ width: '50%', height: '50px', backgroundColor: '#000', color: '#fff', fontSize: '24px', fontWeight: '100', border: 'solid 1px #282828,', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', }}>PROCESS TO ORDERING</Link>
                        <Link className='_mobile'  to='/form' style={{ width: '90%', height: '50px', backgroundColor: '#000', color: '#fff', fontSize: '24px', fontWeight: '100', border: 'solid 1px #282828,', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', }}>PROCESS TO ORDERING</Link>
                    </div>
                </div>

                <div style={{ display: cartItems.length === 0 ? 'block' : 'none', marginTop: '150px', minHeight: "100vh", textAlign: 'center' }}>
                    <h1 style={{  fontSize: '32px', fontWeight: '100', color: '#fff' }}>Cart is empty</h1>
                </div>

                <div className='_mobile' style={{ width: '100%', height: '200px', flexDirection: 'column', bottom: 0, marginTop: '50px', paddingBottom: '30px' }}> 
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: '60px',height: '50px', paddingRight: '10px' }} src='/img/logo/logo.png' alt='img' />
                        <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>abstraction</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <a href='https://vk.com/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>VKONTAKTE</a>
                        <a href='https://t.me/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>TELEGRAM</a>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to='/catalog' style={{ fontSize: '28px', fontWeight: '100', color: '#fff', padding: '0px 10px 0px 10px', marginTop: '5px', cursor: 'pointer', textDecoration: 'none' }}>CATALOG</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CartPage };
