import React, { useEffect  } from 'react';
import '../index.css';
import { Link, useLocation  } from 'react-router-dom';

function CatalogPage() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='body'>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '75px' }}>
                <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>CATALOG</h1>
            </div>

            <div className='_pc' style={{ justifyContent: 'center', gap: '50px', marginTop: '75px' }}>
                <div style={{ width: '500px', height: '600px', position: 'relative', overflow: 'hidden', animation: 'fadeInAnimation ease 4s' }}>
                    <Link to='/catalog/what-is-a-heart' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div style={{ position: 'absolute', bottom: 10, left: 0, textAlign: 'left', marginLeft: '10px', zIndex: '2' }}>
                            <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '20px' }}>T-SHIRT "WHAT IS A HEART"</h1>
                            <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-15px', textDecoration: 'line-through' }}>2 900</h1>
                            <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '18px', marginTop: '-15px' }}>2 600 RUB</h1>
                            <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                            <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>FULL EMBROIDERY</h1>
                            <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>92 COTTON / 8 LYCRA</h1>
                        </div>
                        <div style={{ position: 'relative', textAlign: 'center', zIndex: '2' }}>
                            <span className='text-drag' style={{ position: 'absolute', color: '#fff', fontWeight: '100', fontSize: '32px', right: 20, marginTop: '540px', cursor: 'pointer', textDecoration: 'none' }}>BUY NOW</span>
                        </div>

                        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '600px', backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))' }}></div>
                        <img className='img-drag' style={{ width: '100%', height: '100%', objectFit: 'cover' }} src='/img/catalog/what-is-a-heart/wiah01.jpg' alt='suicide-forest-1'></img>
                    </Link>
                </div>

                <div style={{ width: '500px', height: '600px', position: 'relative', overflow: 'hidden', animation: 'fadeInAnimation ease 4s' }}>
                    <Link to='/catalog/suicide-forest' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div style={{ width: '500px', height: '600px', backgroundColor: '#fff', position: 'relative', overflow: 'hidden', animation: 'fadeInAnimation ease 4s' }}>
                            <div style={{ position: 'absolute', bottom: 10, left: 0, textAlign: 'left', marginLeft: '10px', zIndex: '2' }}>
                                <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '20px' }}>T-SHIRT "SUICIDE FOREST"</h1>
                                <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-15px', textDecoration: 'line-through' }}>2 800</h1>
                                <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '18px', marginTop: '-15px' }}>2 500 RUB</h1>
                                <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                                <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>FULL EMBROIDERY</h1>
                                <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-10px' }}>92 COTTON / 8 LYCRA</h1>
                            </div>
                            <div style={{ position: 'relative', textAlign: 'center', zIndex: '2' }}>
                                <span className='text-drag' style={{ position: 'absolute', color: '#fff', fontWeight: '100', fontSize: '32px', right: 20, marginTop: '540px', cursor: 'pointer', textDecoration: 'none' }}>BUY NOW</span>
                            </div>

                            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '600px', backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))' }}></div>
                            <img className='img-drag' style={{ width: '100%', height: '100%', objectFit: 'cover' }} src='/img/catalog/suicude-forest/suicude-forest-1.jpg' alt='suicide-forest-1'></img>
                        </div>
                    </Link>
                </div>
            </div>

            <div className='_mobile' style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '50px', marginTop: '75px' }}>
            <div style={{ width: '90%', height: '600px', backgroundColor: '#fff', position: 'relative', overflow: 'hidden', animation: 'fadeInAnimation ease 4s' }}>
                <Link to='/catalog/what-is-a-heart' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div style={{ position: 'absolute', bottom: 10, left: 0, textAlign: 'left', marginLeft: '10px', zIndex: '2' }}>
                        <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '18px' }}>T-SHIRT "WHAT IS A HEART"</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-15px', textDecoration: 'line-through'  }}>2 900 RUB</h1>
                        <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '16px', marginTop: '-15px' }}>2 600 RUB</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>FULL EMBROIDERY</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>92 COTTTON / 8 LYCRA</h1>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center', zIndex: '2' }}>
                        <Link to='/catalog/what-is-a-heart' className='text-drag' style={{ position: 'absolute', color: '#fff', fontWeight: '100', fontSize: '24px', right: 20, marginTop: '555px', cursor: 'pointer', textDecoration: 'none' }}>BUY NOW</Link>
                    </div>
                    
                    <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '600px', backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))' }}></div>
                    <img className='img-drag' style={{ width: '100%', height: '100%', objectFit: 'cover' }} src='/img/catalog/what-is-a-heart/wiah01.jpg' alt='suicide-forest-1'></img>
                </Link>
            </div>

            <div style={{ width: '90%', height: '600px', backgroundColor: '#fff', position: 'relative', overflow: 'hidden', animation: 'fadeInAnimation ease 4s' }}>
                <Link to='/catalog/suicide-forest' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div style={{ position: 'absolute', bottom: 10, left: 0, textAlign: 'left', marginLeft: '10px', zIndex: '2' }}>
                        <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '18px' }}>T-SHIRT "SUICIDE FOREST"</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '16px', marginTop: '-15px', textDecoration: 'line-through'  }}>2 800 RUB</h1>
                        <h1 className='text-drag' style={{ color: '#fff', fontWeight: '100', fontSize: '16px', marginTop: '-15px' }}>2 500 RUB</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>NORMAL SIZE / BIG SIZE</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>FULL EMBROIDERY</h1>
                        <h1 className='text-drag' style={{ color: '#ffffff6a', fontWeight: '100', fontSize: '12px', marginTop: '-10px' }}>92 COTTTON / 8 LYCRA</h1>
                    </div>
                    <div style={{ position: 'relative', textAlign: 'center', zIndex: '2' }}>
                        <Link to='/catalog/suicide-forest' className='text-drag' style={{ position: 'absolute', color: '#fff', fontWeight: '100', fontSize: '24px', right: 20, marginTop: '555px', cursor: 'pointer', textDecoration: 'none' }}>BUY NOW</Link>
                    </div>
                    
                    <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '600px', backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))' }}></div>
                    <img className='img-drag' style={{ width: '100%', height: '100%', objectFit: 'cover' }} src='/img/catalog/suicude-forest/suicude-forest-1.jpg' alt='suicide-forest-1'></img>
                </Link>
            </div>

                <div style={{ width: '100%', height: '200px', flexDirection: 'column', bottom: 0, marginTop: '50px' }}> 
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: '60px',height: '50px', paddingRight: '10px' }} src='/img/logo/logo.png' alt='img' />
                        <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>ABSTRACTION</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <a href='https://vk.com/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>VKONTAKTE</a>
                        <a href='https://t.me/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>TELEGRAM</a>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to='/catalog' style={{ fontSize: '28px', fontWeight: '100', color: '#fff', padding: '0px 10px 0px 10px', marginTop: '5px', cursor: 'pointer', textDecoration: 'none' }}>CATALOG</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {CatalogPage};
