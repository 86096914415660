import React from 'react';
import '../index.css';
import { Link } from 'react-router-dom';

function FormPage() {
    async function sendMessageToTelegram(message) {
        const TOKEN = process.env.REACT_APP_TOKEN;
        const CHAT_ID = process.env.REACT_APP_CHAT_ID;
        const url = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: CHAT_ID,
                    text: message,
                }),
            });

            const data = await response.json();
            if (data.ok) {
                alert('order is processed');

                console.log('Message sent successfully:', message);
            } else {
                console.error('Failed to send message:', data);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    }

    const handleClick = () => {
        const suicideForestNormal = parseInt(document.cookie.replace(/(?:(?:^|.*;\s*)suicide-forest-normal\s*=\s*([^;]*).*$)|^.*$/, "$1"));
        const suicideForestBig = parseInt(document.cookie.replace(/(?:(?:^|.*;\s*)suicide-forest-big\s*=\s*([^;]*).*$)|^.*$/, "$1"));
        const whatIsAHeartNormal = parseInt(document.cookie.replace(/(?:(?:^|.*;\s*)what-is-a-heart-normal\s*=\s*([^;]*).*$)|^.*$/, "$1"));
        const whatIsAHeartBig = parseInt(document.cookie.replace(/(?:(?:^|.*;\s*)what-is-a-heart-big\s*=\s*([^;]*).*$)|^.*$/, "$1"));
        const totalPrice = parseInt(document.cookie.replace(/(?:(?:^|.*;\s*)total-price\s*=\s*([^;]*).*$)|^.*$/, "$1"));

        if (suicideForestNormal >= 1 || suicideForestBig >= 1 || whatIsAHeartNormal >= 1 || whatIsAHeartBig >= 1 ) {
            const isWideScreen = window.innerWidth > 1400;

        const telegramId = isWideScreen ? 'telegramLink' : '_mobileTelegramLink';
        const fullNameId = isWideScreen ? 'fullName' : '_mobileАullName';
        const countryId = isWideScreen ? 'country' : '_mobileСountry';
        const sdekAddressId = isWideScreen ? 'sdekAddress' : '_mobileЫdekAddress';
        const phoneNumberId = isWideScreen ? 'phoneNumber' : '_mobilePhoneNumber';
        const commentId = isWideScreen ? 'comment' : '_mobileComment';
        
        const formData = {
            telegramLink: document.getElementById(telegramId).value.trim(),
            fullName: document.getElementById(fullNameId).value.trim(),
            country: document.getElementById(countryId).value.trim(),
            sdekAddress: document.getElementById(sdekAddressId).value.trim(),
            phoneNumber: document.getElementById(phoneNumberId).value.trim(),
            comment: document.getElementById(commentId).value.trim()
        };
    
            if (
                formData.telegramLink &&
                formData.fullName &&
                formData.country &&
                formData.sdekAddress &&
                formData.phoneNumber
            ) {
                let message = `
                New order received:

Telegram Link: ${formData.telegramLink}
Full Name: ${formData.fullName}
Country: ${formData.country}
SDEK Address: ${formData.sdekAddress}
Phone Number: ${formData.phoneNumber}
Comment: ${formData.comment}
                `;
                
                if (suicideForestNormal >= 1) {
                    message += `
Suicide Forest - Normal size: 
amount: ${suicideForestNormal}
                `;
                }
                
                if (suicideForestBig >= 1) {
                    message += `
Suicide Forest - Big size: 
amount: ${suicideForestBig}
                `;
                }

                if (whatIsAHeartNormal >= 1) {
                    message += `
What Is A Heart - Normal size: 
amount: ${whatIsAHeartNormal}
                `;
                }

                if (whatIsAHeartBig >= 1) {
                    message += `
What Is A Heart - Big size: 
amount: ${whatIsAHeartBig}
                `;
                }
                
                message += `
total price: ${totalPrice}
                `;
                
                sendMessageToTelegram(message);
            } else {
                alert("Please fill in all required fields.");
            }
        } else {
            alert("Cart is empty");
        }
    };
    


    return (
        <div className='body'>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
                <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>Form</h1>
            </div>

            <div className='_pcForm' style={{ flexDirection: 'column', width: '100%', justifyContent: 'center', marginTop: '50px'}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 20px 0px 0px', textAlign: 'center',}}>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100' }}>LINK TO YOUR TELEGRAM</h1>
                        <input id="telegramLink" style={{ width: '600px', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='https://t.me/abstractionstore'></input>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>YOUR FULL NAME</h1>
                        <input id="fullName" style={{ width: '600px', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Ivanov Ivan Ivanovich'></input>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>COUNTRY</h1>
                        <input id="country" style={{ width: '600px', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Russia'></input>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>THE ADDRESS OF THE SDEK</h1>
                        <input id="sdekAddress" style={{ width: '600px', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Russia, Moscow, Lenina Street'></input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 0px 20px', textAlign: 'center', }}>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100' }}>YOUR PHONE NUMBER</h1>
                        <input id="phoneNumber" style={{ width: '600px', height: '60px', border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='+7 (999) 999-99-99' type="tel"></input>
                        <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>COMMENT (OPTIONAL)</h1>
                        <textarea id="comment" style={{ width: '590px', height: '297px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', resize: 'none' }} placeholder='Comment'></textarea>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px', }}>
                    <Link to='/' onClick={handleClick} style={{ display: 'flex', width: '50%', height: '50px', backgroundColor: '#000', color: '#fff', fontSize: '24px', fontWeight: '100', border: 'solid 1px #282828', justifyContent: 'center', alignItems: 'center', textDecoration: 'none' }}>PLACE AN ORDER</Link>
                </div>
            </div>

            <div className='_mobileForm' style={{ flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100' }}>Link to your Telegram</h1>
                <input id="_mobileTelegramLink" style={{ width: '95%', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='https://t.me/abstractionstore'></input>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>Your full name</h1>
                <input id="_mobileАullName" style={{ width: '95%', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Ivanov Ivan Ivanovich'></input>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>Country</h1>
                <input id="_mobileСountry" style={{ width: '95%', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Russia'></input>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>The address of the SDEK</h1>
                <input id="_mobileЫdekAddress" style={{ width: '95%', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='Russia, Moscow, Lenina Street'></input>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>Your phone number</h1>
                <input id="_mobilePhoneNumber" style={{ width: '95%', height: '60px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px' }} placeholder='+7 (999) 999-99-99'></input>
                <h1 style={{ color: '#fff', fontSize: '24px', fontWeight: '100', marginTop: '24px' }}>Comment (optional)</h1>
                <textarea id="_mobileComment" style={{ width: '95%', height: '200px',  border: 'solid 1px #282828', color: '#fff', fontSize: '26px', fontWeight: '100', backgroundColor: '#010101', marginTop: '-7.5px', paddingLeft: '10px', paddingTop: '10px', resize: 'none' }} placeholder='Comment'></textarea>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px', }}>
                    <Link to='/' onClick={handleClick} style={{ display: 'flex', width: '50%', height: '50px', backgroundColor: '#000', color: '#fff', fontSize: '24px', fontWeight: '100', border: 'solid 1px #282828,', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', }}>place an order</Link>
                </div>
                
            </div>
        </div>
    )
}

export { FormPage };
